import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "./components/Loader"

// Импортируем все страницы с помощью React.lazy()
const Home = lazy(() => import("./pages/Home/Home"));
const Children = lazy(() => import("./pages/Children/Children"));
const Offis = lazy(() => import("./pages/Offis/Offis"));
const Individual = lazy(() => import("./pages/Individual/Individual"));
const Foregn = lazy(() => import("./pages/Foregn/Foregn"));
const ExamData = lazy(() => import("./pages/ExamData/ExamData"));
const ExamAnswers = lazy(() => import("./pages/ExamAnswers/ExamAnswers"));
const Answer = lazy(() => import("./pages/ExamAnswers/container/Answer"));
const AnswerNew = lazy(() => import("./pages/ExamAnswers/container/AnswerNew"));
const ExamSubject = lazy(() => import("./pages/ExamSubject/ExamSubject"));

function App() {
  return (
    <div className="App">
      {/* Оберните Routes в Suspense */}
      <Suspense fallback={<Loader/>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ibtidai-sinifler" element={<Children />} />
          <Route path="/offis-proqramlari" element={<Offis />} />
          <Route path="/ferdi-hazirliqlar" element={<Individual />} />
          <Route path="/xarici-diller" element={<Foregn />} />
          <Route path="/imtahan-movzulari" element={<ExamSubject />} />
          <Route path="/imtahan-tarixleri" element={<ExamData />} />
          <Route path="/imtahan-cavablari" element={<ExamAnswers />} />
          <Route path="/imtahan-cavablari/18fevral" element={<Answer />} />
          <Route path="/imtahan-cavablari/25fevral" element={<AnswerNew />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
